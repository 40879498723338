.pt-logo {
  display: flex;
  align-items: center;
  padding: 0.5rem 0 1.5rem 1rem;
  font-size: medium;
  background-color: var(--sidebar-bg-color);
}

.pt-logo .pt-img {
  padding-right: 5px;
  width: 48px;
  height: auto;
}
.pt-logo .pt-text1 {
  color: var(--dk-gray-100);
  font-weight: 500;
}
.pt-logo .pt-text2 {
  color: var(--logo-yellow-color);
  font-weight: 600;
}
