.search-stocktickers .ant-input {
  font-weight: bold;
  color: var(--dk-gray-100);
}

.ant-input-group-wrapper {
  width: 100%;
  background-color: var(--dk-dark-bg);
}

.ant-input-search .ant-input {
  color: var(--navbar-bg-color);
  font-weight: bold;
}
