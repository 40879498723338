.password-addon-icon {
  background-color: var(--dk-gray-200);
  border-radius: 8px;
}

.password-form h5 {
  color: var(--dk-gray-300);
}

.password-form ul,
.password-form .li {
  color: var(--dk-gray-500);
  font-size: 80%;
  margin-left: 1.75rem;
}
