.portfolio-summary-card {
  width: 100%;
}

.portfolio-summary-card-detail .button-add-symbol {
  /* width: 100%; */
  color: var(--dk-gray-100);
}

.portfolio-summary-card-empty {
  width: 100%;
  color: var(--dk-gray-100);
  font-size: large;
}

.portfolio-summary-card-empty-row {
  padding: 1rem 0;
}

.portfolio-summary-card-heading {
  font-weight: 600;
  font-size: medium;
  color: var(--dk-gray-400);
  background-color: var(--dk-darker-bg);
}

.portfolio-summary-card-row {
  /* font-weight: 600; */
  color: var(--dk-gray-300);
  background-color: var(--dk-darker-bg);
  align-items: center;
}

.portfolio-summary-card-divider {
  /* font-weight: 600; */
  color: var(--dk-gray-100);
  background-color: var(--dk-darker-bg);
}

.portfolio-summary-card-net-worth {
  font-size: x-large;
  font-weight: bold;
}

.ant-card {
  background-color: var(--dk-darker-bg);
  padding: 0 1em;
}
